import React from 'react'

import { UpcomingEvents } from '../components/UpcomingEvents/UpcomingEvents'
import { UsefulResources } from '../components/UsefulResources/UsefulResources'

export const HomeView = () => {
  return (
    <div className="fullScreen scrollableY">
      <UpcomingEvents />
      {/* <UsefulResources /> */}
    </div>
  );
}
