import { DateTime } from "luxon";
import { IDate } from "../interfaces/checkinEventCard.interfaces";

export const getFormattedDate = (date?: string): IDate => {
  if (!date) {
    return {
      dayName: "",
      dayNumber: "",
      month: "",
      year: ""
    };
  }

  const parsedDate: DateTime = DateTime.fromISO(date);

  const dayOfWeek = parsedDate.toFormat("EEEE");
  const dayOfMonth = parsedDate.toFormat("d");
  const month = parsedDate.toFormat("MMMM");
  const year = parsedDate.toFormat("yyyy");

  return {
    dayName: dayOfWeek,
    dayNumber: dayOfMonth,
    month: month,
    year: year
  };
};

export const is_after_party = (time: number): boolean => {
  return (time > 0 && time < 9)
}

export const parseDateForEmail = (
  time: string | null | undefined,
  date: string | null | undefined
): String | null | undefined => {
    if (time && date) {
    const parsedTime = DateTime.fromISO(time.split(' ')[1]).hour
    let parsedDate = DateTime.fromISO(date)

    if (is_after_party(parsedTime)) {
      parsedDate = parsedDate.minus({ days: 1 })
      return parsedDate.toFormat("cccc MMMM d, yyyy")
    } else {
      return parsedDate.toFormat("cccc MMMM d, yyyy")
    }
  }
}

export const parseTimeForEmail = (
  time: string | null | undefined
): String | null | undefined => {
  if (time) {
    let parsedTime

    if(time.split(' ')[1]) {
      parsedTime = DateTime.fromISO(time.split(' ')[1])
    } else {
      parsedTime = DateTime.fromISO(time)
    }

    if (is_after_party(parsedTime.hour)) {
      const timeFormatted = parsedTime.toFormat("hh:mm a")
      return timeFormatted + ' - Arrive by Next Day at ' + timeFormatted
    } else {
      return parsedTime.toFormat("hh:mm a")
    }
  }
}