import { DateTime } from "luxon";
import { getOpenDoor } from "@venuedriver/ui-driver";

import { getFormattedDate } from "./date";
import { getImageLink } from "./getUrl";

import { CheckinEventCardFilter } from "../interfaces/global";
import { ICheckinEventCard } from "../interfaces/checkinEventCard.interfaces";

import { useVenueByVenueId } from "../hooks/GraphQLHooks/useVenueByVenueId";
import { Event } from "../models";
import { getFormattedTime } from "./time";
import { Venue } from "../API";

type VenueToSort = { name: string, id: string, venueDriverVenueID: number }

type SortCheckinEventCards = {
  events: Event[];
  venue: VenueToSort;
  venues: Venue[];
  filter?: CheckinEventCardFilter;
  eventsToShow?: number;
};

export const getCheckinEventCards = async({
  events,
  venue,
  venues,
  filter = "sameDay",
  eventsToShow = 2,
}: SortCheckinEventCards): Promise<ICheckinEventCard[]> => {
  return Promise.all(events
    .map((event: Event) => {
      const {
        id,
        date_string,
        title,
        open_string,
        venueDriverEventID,
        venueDriverVenueID,
        time_zone,
      } = event;

      const openDoor = getOpenDoor(open_string!, time_zone!);
      const formattedDate = getFormattedDate(date_string!);
      const image = getImageLink(venueDriverEventID ? venueDriverEventID.toString() : "");

      const event_ = {
        id,
        dateWithoutFormat: date_string,
        venueDriverEventID,
        venueDriverVenueID,
        date: formattedDate,
        image,
        live: false, // WARN: hardcoded for now
        eventTitle: title,
        venue: venueDriverVenueID ? venueDriverVenueID.toString() : "",
        eventSchedule: openDoor,
        eventId: venueDriverEventID,
      };

      return event_;
    })
    .sort((a, b) => {
      const dateA = DateTime.fromISO(a.dateWithoutFormat!);
      const dateB = DateTime.fromISO(b.dateWithoutFormat!);

      return dateA.valueOf() - dateB.valueOf();
    })
    .filter((event) => {
      const { dateWithoutFormat } = event;
      const parsedDate = DateTime.fromISO(dateWithoutFormat!);

      const filterOptions = {
        all: () => true,
        past: () => parsedDate < DateTime.now(),
        sameDay: () => parsedDate.hasSame(DateTime.now(), "day"),
        sameWeek: () => parsedDate.hasSame(DateTime.now(), "week"),
        sameMonth: () => parsedDate.hasSame(DateTime.now(), "month"),
        fromNowUntilThreeDays: () => {
          const now = DateTime.now();
          const yesterday = now.minus({ days: 1 });
          const threeDaysFromNow = now.plus({ days: 3 });

          return parsedDate > yesterday && parsedDate < threeDaysFromNow;
        }
      };

      return filterOptions[filter]();
    })
    .filter((event) => {
      // Filter by Venue selected
      if (venue.venueDriverVenueID == 0) {
        return event
      } else {
        if (venue.venueDriverVenueID &&
          event.venueDriverVenueID == venue.venueDriverVenueID
        ) {
          return event
        }
      }
    })
    .slice(0, eventsToShow)
    .map(async (event) => {
      const newEvent = { ...event };
      const { venue } = newEvent;
      const venueName = await useVenueByVenueId(parseInt(venue));

      delete newEvent.dateWithoutFormat;
      return { ...newEvent, venue: venueName };
    })
  );
}
