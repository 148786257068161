import { useContext, useEffect, useState } from "react"
import { DataStore } from "@aws-amplify/datastore";
import { DateTime } from 'luxon';
import { Event, Venue } from "../../models"
import { Context } from "../../context/GlobalContext";

export const useEvents = (): { events: Event[] } => {
  const [events, setEvents] = useState<Event[]>([]);
  const { venue } = useContext(Context);

  useEffect(() => {
    const fetchEvents = async () => {
      const fromDate = DateTime.now().setZone('US/Pacific');
      const toDate = fromDate.plus({days: 3})
      let events;

      if (venue.id != '') {
        events = await DataStore.query(Event, (e) =>
          e.date("ge", fromDate.toFormat('yyyy-MM-dd'))
            .date("lt", toDate.toFormat('yyyy-MM-dd'))
            .venueID("eq", venue.id)
        );
      } else {
        events = await DataStore.query(Event, (e) =>
          e.date("ge", fromDate.toFormat('yyyy-MM-dd'))
            .date("lt", toDate.toFormat('yyyy-MM-dd'))
        );
      }
      setEvents(events);
    }

    fetchEvents();
  }, [venue.id]);

  return {
    events,
  };
}
