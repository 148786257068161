import { API, graphqlOperation } from 'aws-amplify'
import {listVenues} from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';

export const useVenueByVenueId = (venueId: number): Promise<string> => {
  const getData = async () => {
    try {
      const responseData: GraphQLResult<any> = await API.graphql(
        graphqlOperation(listVenues, {
          filter: {
            venueDriverVenueID: {
              eq: venueId,
            },
          },
        })
      );
      return responseData.data.listVenues.items[0].name;
    } catch (err) {
      console.error(`error fetching venue ${venueId}`, err);
      return 'unknown';
    }
  };

  return getData();
};