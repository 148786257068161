import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { BsCardList } from 'react-icons/bs';

import { CheckinEventCard, useMediaQuery, CheckinEventCardSkeleton } from '@venuedriver/ui-driver';

import { Context } from '../../../../context/GlobalContext';

import { useDataStore } from '../../../../hooks/contextHooks/useDataStore';
import { useEvents } from '../../../../hooks/eventsHooks/useEvents';

import { getCheckinEventCards } from '../../../../utils/getCheckinEventCards';

import { ICheckinEventCard } from '../../../../interfaces/checkinEventCard.interfaces';
import styles from './UpcomingEvents.module.css'

export const UpcomingEvents = () => {
  const route = useRouter();
  const { setIsCheckinEventsPageActive } = useDataStore();

  const [checkinEventCard, setCheckinEventCard] = useState<ICheckinEventCard[]>([])

  const { events } = useEvents();
  const { venue, venues } = useContext(Context);

  const isTablet = useMediaQuery("(min-width: 576px) and (max-width: 1200px)");
  const isMobile = useMediaQuery("(max-width: 575px)");

  const [loading, setLoading] = useState(true);

  // ##########################
  // Restart DataStore, this way we can sync the events
  // useEffect(() => {
  //   const restartDataStore = async () => {
  //     await DataStore.stop();
  //     await DataStore.start();
  //   };

  //   restartDataStore()
  // }, []);

  useEffect(() => {
    setIsCheckinEventsPageActive([0]);

    if (events.length > 0) {
      setLoading(true);

      const getData = async () => {
        const data = await getCheckinEventCards({
          events,
          venue,
          venues,
          filter: 'fromNowUntilThreeDays',
          eventsToShow: 10,
        });

        setCheckinEventCard(data);
        setIsCheckinEventsPageActive(data.map((event: ICheckinEventCard) => event.venueDriverEventID!));
        setLoading(false)
      }

      getData();
    }
  }, [events, setIsCheckinEventsPageActive, venue, venues]);

  const containerClasses = classNames({
    [styles.container]: true,
    [styles['header-mobile']]: isMobile,
    [styles['header-tablet']]: isTablet,
    [styles['header-desktop']]: !isMobile && !isTablet,
  });

  return (
    <div className={containerClasses}>
      <div className={styles["sub-container"]}>
        <div className={styles['title-and-icon-container']}>
          <h3 className={styles.title}>Upcoming events</h3>
          <div
            onClick={() => route.push('/checkin/all-events')}
            className={styles['icon-all-events-container']}>
            <BsCardList size={24} color='white' />
          </div>
        </div>
        <p className={styles.description}>
          Start scanning tickets and assigning wristbands
        </p>
        <div className={styles["events-container"]}>
          {loading && new Array(2).fill(0).map((_, index) => (
            <div className={styles['event-container']} key={index}>
              <CheckinEventCardSkeleton />
            </div>
          ))}

          {!loading && checkinEventCard.map((card: ICheckinEventCard) => {
            return (
              <div className={styles['event-container']} key={card.id}>
                <CheckinEventCard
                  {...card}
                  route={route}
                />
              </div>
            );
          })}


          {!loading && checkinEventCard.length == 0 && (
            <p className={styles.no_events}>
              {venue.name ? `No upcoming events for ${venue.name}` : `No upcoming events`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}